.Worldpay-Add-Card-Form .checkout .col-2 {
    display: flex;
  }
  .Worldpay-Add-Card-Form .checkout .col-2 .col:first-child {
    margin-right: 15px;
  }
  .Worldpay-Add-Card-Form .checkout .col-2 .col:last-child {
    margin-left: 15px;
  }
  .Worldpay-Add-Card-Form .checkout .field {
    height: 40px;
    border-bottom: 1px solid lightgray;
  }
  .Worldpay-Add-Card-Form .checkout .field#card-pan {
    margin-bottom: 30px;
  }
