html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
    min-height: 100vh;
    min-height: -webkit-fill-available;
}
ol,
ul {
    list-style: none;
}
* {
    box-sizing: border-box;
    outline: none;
    font: inherit;
}
html,
body,
#root {
    /* Fallback property for containing whole page */
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}

.hidden-scroll {
    overflow: moz-scrollbars-none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.hidden-scroll::-webkit-scrollbar {
    display: none;
}

/* Disable google's hideous massive logo on the maps */
a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}
.gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
}

.gmnoprint div {
    border-radius: 10px !important;
}

html {
    height: -webkit-fill-available;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.apple-pay-button {
    -webkit-appearance: -apple-pay-button;
    width: 100%;
    -apple-pay-button-type: check-out;
    -apple-pay-button-type: order;
}

.Worldpay-3DSForm {
    position: fixed;
    top: 48px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 9999999999999;
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid transparent;
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.01);
}
